import React from 'react';
import { graphql } from 'gatsby';
import { Layout, SEO } from 'components';
import { Slices } from '../slices/slices';

const Page = (props) => {
  const { location, data } = props;
  const siteName = data && data.site && data.site.siteMetadata && data.site.siteMetadata.siteName;
  const dataSlices = data && data.prismicArticle && data.prismicArticle.data && data.prismicArticle.data.body;
  const dataPage = data && data.prismicPage && data.prismicPage.data;
  const firstSlice = dataSlices && dataSlices[0] && dataSlices[0].slice_type;
  const isHero = firstSlice === 'hero_image' || firstSlice === 'hero_video';

  // SEO
  const title = dataPage && dataPage.title && dataPage.title.text;
  const desc = dataPage && dataPage.description && data.description.text;

  return (
    <Layout location={location} isHero={isHero}>
      <SEO title={`${title} | ${siteName}`} desc={desc} />
      <Slices data={dataSlices} />
    </Layout>
  );
};

export default Page;

export const Query = graphql`
  query($uid: String!) {
    site {
      siteMetadata {
        siteName
      }
    }
    prismicArticle(uid: { eq: $uid }) {
      data {
        title {
          text
        }
        description {
          text
        }
        body {
          ...ArticleAccordion
          ...ArticleContentDisplay
          ...ArticleFeatures
          ...ArticleForm
          ...ArticleHeroImage
          ...ArticleHeroVideo
          ...ArticleImageSlider
          ...ArticleImageText
          ...ArticleList
          ...ArticleProcess
          ...ArticleQuote
          ...ArticleStatistics
          ...ArticleText
          ...ArticleVideo
        }
      }
    }
  }
`;

// ...ArticleFeaturesImages
